<template>
    <ProcessCategory
        :data="processCategoryOptions"
        v-model:categoryVisible="processCategoryVisible"
        typeName="工序"
        @onSelect="onChangeProcessCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}工序`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="工序编码"
                name="code"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入工序编码' },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :maxlength="100"
                    showCount
                    :disabled="Boolean(upDateId)"
                />
            </a-form-item>

            <a-form-item
                label="工序名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入工序名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="工序类型"
                name="type"
                :rules="[{ required: true, message: '请选择工序类型' }]"
            >
                <a-radio-group v-model:value="formData.type">
                    <a-radio
                        v-for="(item, index) in processTypeOptions"
                        :key="index"
                        :value="item.code"
                    >
                        {{ item.name }}
                    </a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="工序类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (processCategoryVisible = true)"
                        @onDelete="() => onChangeProcessCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="检验要求"
                name="needDetect"
                :rules="[{ required: true, message: '请选择检验要求' }]"
            >
                <a-radio-group v-model:value="formData.needDetect">
                    <a-radio :value="true">是</a-radio>
                    <a-radio :value="false">否</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item
                label="作业类型"
                name="workMode"
                :rules="[{ required: true, message: '请选择作业类型' }]"
            >
                <a-radio-group v-model:value="formData.workMode">
                    <a-radio value="NOTHING">无</a-radio>
                    <a-radio value="MATERIAL_BIND">物料绑定</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="标准工时/小时" name="standardWorkDuration">
                <a-form-item>
                    <div class="contant-item">
                        <a-input-number
                            style="width: 150px"
                            v-model:value="formData.standardWorkDuration"
                            :min="0"
                            :precision="2"
                            :maxlength="100"
                            showCount
                        />
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="标准产能"
                name="standardCapacity"
                :rules="[{ validator: checkAllSpace }]"
            >
                <a-input v-model:value="formData.standardCapacity" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="人员配置" name="staffing" :rules="[{ validator: checkAllSpace }]">
                <a-input v-model:value="formData.staffing" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="工艺要求"
                name="requirements"
                :rules="[{ validator: checkAllSpace }]"
            >
                <a-textarea
                    v-model:value="formData.requirements"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item label="工序附件" name="stepFileIds">
                <UploadFile
                    businessType="BAS_WORK_STEP_FILE"
                    :files="formData.stepFileIds"
                    @onSuccess="fileInfo => onSuccess(fileInfo)"
                    @deleteFile="index => onDeleteFile(index)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiProcessUpdate, apiProcessIndexData } from "@/api";
import Select from "@/components/Select";
import ProcessCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import UploadFile from "@/components/UploadFile";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        ProcessCategory,
        DownOutlined,
        UploadFile,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            options: [
                { label: "天", value: "DAYS" },
                { label: "小时", value: "HOURS" },
                { label: "分钟", value: "MINUTES" },
            ],
            processTypeOptions: [],
            processCategoryOptions: [],
            workModeOptions: [],
            processCategoryVisible: false,
            formData: {
                id: "",
                name: "",
                code: "",
                type: "",
                workMode: "NOTHING",
                standardWorkDuration: "",
                standardCapacity: "",
                staffing: "",
                requirements: "",
                needDetect: "",
                category: "",
                categoryCode: "",
                categoryName: "",
                stepFileIds: [],
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let stepFileIds = state.formData.stepFileIds.map(item => item?.id);
            let res = await apiProcessUpdate({
                ...state.formData,
                stepFileIds,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiProcessIndexData(id);
            if (res.status === "SUCCESS") {
                const { typeCandidate, categoryCandidate, basWorkStep, workModeCandidate } =
                    res?.data;
                state.processTypeOptions = typeCandidate;
                state.processCategoryOptions = categoryCandidate;
                state.workModeOptions = workModeCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = basWorkStep ? basWorkStep?.[key] : "";
                });
                state.formData.stepFileIds = basWorkStep?.stepFiles || [];
                if (!basWorkStep) {
                    state.formData.category = "";
                    state.formData.type = "INTEGRATED";
                    state.formData.workMode = "NOTHING";
                }
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangeProcessCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSuccess = fileInfo => {
            state.formData.stepFileIds = [...state.formData.stepFileIds, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.stepFileIds.splice(index, 1);
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.category = "";
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeProcessCategory,
            onSuccess,
            onDeleteFile,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
